export const contracts = {
  multicall: {
    address: "0xc0F5345C65eda3016510A278BBfC0CD7F063c0FE",
  },
  liquidityMiningManager: {
    address: "0x636B713332F1e441b629905310B2dF98610407B2",
  },
  escrowedReward: {
    address: "0x6D3005F0cd1382eF962Ac19AA9CD0cE49f53f12e",
  },
  view: {
    address: "0x22bB102b84735564DA3a394bAFcAFeE049467dC8",
  },
  singleTokenPool: {
    address: "0x750Fc63264c0d08472387DA13e39428be4a7892D",
  },
  lpPool: {
    address: "0x263A00d1fFe57fd3aBC5e87E4A475e1341Cb769F",
  },
  uniswapLpPairAddress : {
    address: "0x155d200920a3b0cf11a97838715AB69A110C8995"
  },
  copperLaunchPoolContract:{
    address: "0xeEDcA0C2cBa983b718C66094fC8E41F9eD52F82a"
  },
  balancerVault: {
    address: "0xba12222222228d8ba445958a75a0704d566bf2c8",
    poolId: "0xeedca0c2cba983b718c66094fc8e41f9ed52f82a00020000000000000000011c"
  }
};
