export const pools = [
  {
    pid: 0,
    stakedTokenName: "LOOT",
    stakedTokenSymbol: "LOOT",
    rewardTokenName: "LOOT",
    stakedTokenDecimals: 18,
    tokenAddress: "0x14a9a94e555fdd54c21d7f7e328e61d7ebece54b",
    poolAddress: "0x750Fc63264c0d08472387DA13e39428be4a7892D",
    getUrl:
      "https://pancakeswap.finance/swap?outputCurrency=0x14a9a94e555fdd54c21d7f7e328e61d7ebece54b&inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    weight: 0.4,
    toFixed: 2,
  },
  {
    pid: 1,
    stakedTokenName: "LOOT:BUSD LP",
    stakedTokenSymbol: "LOOT:BUSD LP",
    rewardTokenName: "LOOT",
    stakedTokenDecimals: 18,
    tokenAddress: "0x155d200920a3b0cf11a97838715AB69A110C8995",
    poolAddress: "0x263A00d1fFe57fd3aBC5e87E4A475e1341Cb769F",
    getUrl:
      "https://pancakeswap.finance/add/0x14a9a94e555fdd54c21d7f7e328e61d7ebece54b/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
    weight: 0.6,
    toFixed: 2,
  },
];
